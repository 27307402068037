import { ApolloClient, DefaultOptions, InMemoryCache, gql } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';
import { setContext } from '@apollo/client/link/context';
import jwt from 'jsonwebtoken';
import { Modal } from 'antd';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const checkTokenStatus = async (): Promise<boolean> => {
  let access_token, refresh_token;
  if (typeof localStorage !== 'undefined') {
    access_token = localStorage.getItem('access_token');
    refresh_token = localStorage.getItem('refresh_token');
  }

  // Protected route must has token
  if (location?.pathname?.includes('/manage-') && (!access_token || !refresh_token)) {
    return false;
  }

  return true;
};

let promptingLogin = false;

const promptLogin = async () => {
  if (!promptingLogin) {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
    Modal.error({
      title: 'Session expired',
      content: 'Please log in again',
      closable: false,
      maskClosable: false,
      onOk: () => {
        window.location.href = '/login';
      },
      keyboard: false,
    });
    promptingLogin = true;
  }
};

const publicPaths = ['payment', '/direct-purchase'];
const currentPath = window.location.pathname;
export const isPublicPath = publicPaths.some((path) => currentPath.startsWith(path));

const authLink = setContext(async ({ operationName }, { headers }) => {
  if (operationName === 'RefreshAccessToken' || publicPaths.some((path) => currentPath.startsWith(path))) {
    return {
      headers: {
        ...headers,
      },
    };
  }

  let access_token, refresh_token;

  if (await checkTokenStatus()) {
    if (typeof localStorage !== 'undefined') {
      access_token = localStorage.getItem('access_token');
      refresh_token = localStorage.getItem('refresh_token');
    }
    const token_decoded = access_token && jwt.decode(access_token);
    const current_timestamp = Date.now().valueOf() / 1000;

    //@ts-ignore
    if (refresh_token && (!token_decoded || current_timestamp > token_decoded.exp)) {
      //Token has expired, request a new one
      try {
        access_token = await getAccessToken(refresh_token);
        if (access_token) {
          localStorage.setItem('access_token', access_token);
        } else {
          throw 'Failed to refresh token';
        }
      } catch (err) {
        await promptLogin();
      }
    }
  } else {
    await promptLogin();
  }

  return {
    headers: {
      ...headers,
      authorization: access_token ? access_token : '',
    },
  };
});

const getAccessToken = async (refresh_token: string): Promise<string | null> => {
  try {
    const { data } = await apolloClient.query({
      fetchPolicy: 'network-only',
      query: gql`
        query RefreshAccessToken($refresh_token: String!) {
          refreshAccessToken(refresh_token: $refresh_token)
        }
      `,
      variables: { refresh_token },
    });
    return data.refreshAccessToken;
  } catch (err) {
    console.log('err', err);
    return null;
  }
};

export const apolloClient = new ApolloClient({
  link: authLink.concat(
    createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      includeExtensions: true,
    }),
  ),
  defaultOptions: defaultOptions,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
