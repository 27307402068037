import { ILot } from './lotTypes';
import { INomineeBeneficiary } from './nomineeBeneficiaryTypes';
import { IResale } from './resaleTypes';
import { CreatedOrUpdatedBy, ISale } from './salesTypes';

export enum ProductStatusEnum {
  Available = 'Available',
  Unavailable = 'Unavailable',
  Booked = 'Booked',
  Sold = 'Sold',
}

export enum ProductAmountTypeEnum {
  Percentage = 'Percentage',
  Fixed = 'Fixed',
}

export enum ProductTabEnum {
  Sales = 'Sales',
  Beneficiaries = 'Beneficiaries',
  Nominees = 'Nominees',
  MiscSales = 'MiscSales',
}

export enum ProductTypeEnum {
  Lot = 'Lot',
  Misc = 'Misc',
}

export interface IProductCreate {
  id: string;
  area: string;
  type: string;
  status: ProductStatusEnum;
  direction: string;
  costInstantCase: number;
  costPreNeedFullPayment: number;
  offerTombPackage: boolean;
  tombPackagePrice: number;
  costPreNeedInstalment: number;
  downPayment: number;
}

export interface IProductMiscSale {
  sale: ISale;
  date: string;
}

export interface IProductMiscSales {
  sales: IProductMiscSale[];
  createdBy: CreatedOrUpdatedBy;
  updatedBy: CreatedOrUpdatedBy;
}

export interface IProductProductValue {
  product: string;
  amount: number;
}

export interface PaymentInstalmentEntry {
  months: number;
  perMonth: number;
}

export interface PaymentInstalment {
  total: number;
  entries: [PaymentInstalmentEntry];
}

export interface PaymentPreNeed {
  cash: number;
  instalment: PaymentInstalment;
  cashPaymentPlan: PaymentInstalment;
}
export interface IProduct {
  id: string;
  productId: string;
  name: string;
  oldName: string;
  area: string;
  type: string;
  status: ProductStatusEnum;
  direction: string;
  description: string;
  licensingFee: number;
  continuityFee: number;
  aluminiumEmboss: number;
  backwallWalkway: number;
  costInstantCase: number;
  costPreNeedFullPayment: number;
  costPreNeedInstalment: number;
  costDirectPurchaseInstalment: number;
  costDirectPurchaseFullPayment: number;
  offerTombPackage: boolean;
  tombPackagePrice: number;
  offerDirectPurchase: boolean;
  directPurchaseDiscountType: ProductAmountTypeEnum;
  directPurchaseDiscount: number;
  preNeedCashDiscount: number;
  preNeedCashDiscountType: ProductAmountTypeEnum;
  preNeedInstalmentDiscount: number;
  preNeedInstalmentDiscountType: ProductAmountTypeEnum;
  downPayment: number;
  downPaymentType: ProductAmountTypeEnum;
  lot: ILot;
  sales: ISale[];
  miscSales: IProductMiscSale[];
  miscellaneousSales: IProductMiscSales;
  nominees: INomineeBeneficiary[];
  beneficiaries: INomineeBeneficiary[];
  resale: IResale;
  productValue: number;
  createdAt: string;
  updatedAt: string;
}
